const Enums = {
  HttpStatusCodes: {
    Success: 200,
    NotFound: 404,
    Unauthorized: 401,
    InternalServerError: 500,
  },
  EntityStatus: {
    Enabled: 0,
    Disabled: 1,
    Deleted: 2,
  },
  InvitationStatus: {
    Pending: 0,
    Accepted: 1,
    Canceled: 2,
  },
  Roles: {
    Admin: 0,
    Manager: 1,
    Basic: 2,
  },
};

export default Enums;
