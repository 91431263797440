import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Page, Card, Button } from "@ediframework/ui.components";

const InvalidInvitation = () => {
  const { t } = useTranslation(["InvalidInvitation", "Shared"]);
  const [btnLoading, setBtnLoading] = useState(false);

  const goToLogin = () => {
    setBtnLoading(true);
    window.location.href = import.meta.env.VITE_MAIN_URL;
  };

  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <div className="text-center pb-3">
            <i className="fad fa-times-circle fa-4x text-danger"></i>
          </div>
          <Card.Title className="fw-bold text-center pb-3">
            {t("Title")}
          </Card.Title>
          <Card.Text>{t("Description")}</Card.Text>

          <Button
            id="login"
            type="primary"
            btnBlock="true"
            loading={btnLoading}
            onClick={goToLogin}
          >
            {t("Shared:LogIn")}
          </Button>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default InvalidInvitation;
