import React, { useState, useContext, createContext } from "react";
import Enums from "../types/Enums";

const AccountDataContext = createContext();

const AccountDataProvider = (props) => {

  const [accountData, setAccountData] = useState({
    Id: null,
    Name: null,
    Status: Enums.EntityStatus.Enabled,
  });

  return (
    <AccountDataContext.Provider value={{ accountData, setAccountData }}>
      {props.children}
    </AccountDataContext.Provider>
  );
};

const useAccountData = () => {
  const context = useContext(AccountDataContext);
  if (context === undefined) {
    throw new Error("useAccountData must be used within a AccountDataProvider");
  }
  return context;
};

export { AccountDataProvider, useAccountData };
