import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import authentication from "../helpers/authentication";

import { Page, Card, Button } from "@ediframework/ui.components";

const AccountDisabled = () => {
  const { t } = useTranslation(["AccountDisabled", "Shared"]);
  const [btn1Loading, setBtn1Loading] = useState(false);
  const [btn2Loading, setBtn2Loading] = useState(false);

  const goToEdiHome = () => {
    setBtn1Loading(true);
    window.location.href = "https://goedi.com/";
  };

  const signOut = () => {
    setBtn2Loading(true);
    authentication.signOut();
  };

  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <div className="text-center pb-3">
            <i className="fad fa-user-alt-slash fa-4x text-primary"></i>
          </div>
          <Card.Title className="fw-bold text-center pb-4">
            {t("Title")}
          </Card.Title>
          <Card.Text>{t("Description")}</Card.Text>
          <Button
            id="logout"
            type="primary"
            btnBlock="true"
            loading={btn1Loading}
            onClick={goToEdiHome}
          >
            <i className="fal fa-home"></i> {t("Shared:GoToEDiHome")}
          </Button>
          {authentication.isLoggedIn() && (
            <Button
              id="logout"
              type="default"
              btnBlock="true"
              loading={btn2Loading}
              onClick={signOut}
            >
              <i className="fal fa-power-off"></i> {t("Shared:Logout")}
            </Button>
          )}
        </Card.Body>
      </Card>
    </Page>
  );
};

export default AccountDisabled;
