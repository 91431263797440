import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Page,
  Card,
  Button,
  useQueryParams,
} from "@ediframework/ui.components";

const LoggedOut = () => {
  const { t } = useTranslation(["LoggedOut", "Shared"]);
  const queryParams = useQueryParams();

  const [btnLoading, setBtnLoading] = useState(false);
  const [iframeUrls, setIframeUrls] = useState([]);

  const goToLogin = () => {
    setBtnLoading(true);
    window.location.href = import.meta.env.VITE_MAIN_URL;
  };

  const getSignOutIframes = () => {
    return iframeUrls.map((url, index) => {
      return (
        <iframe
          className="d-none"
          key={`iframe-signout-${index}`}
          title={`iframe-signout-${index}`}
          src={url}
        />
      );
    });
  };

  useEffect(() => {
    if (!queryParams.get("broadcast")) {
      const urls = [
        `${import.meta.env.VITE_MAIN_URL}signout`,
        `${import.meta.env.VITE_ADMIN_URL}signout`,
        `${window.location.origin}/signout`,
      ];
      setIframeUrls(urls);
    }
  }, []);

  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <div className="text-center pb-3">
            <i className="fad fa-power-off fa-4x text-primary"></i>
          </div>
          <Card.Title className="fw-bold text-center pb-3">
            {t("LoggedOut")}
          </Card.Title>
          <Card.Text>{t("Description")}</Card.Text>
          <Button
            id="login"
            type="primary"
            btnBlock="true"
            loading={btnLoading}
            onClick={goToLogin}
          >
            {t("Shared:LogIn")}
          </Button>
        </Card.Body>
      </Card>
      {getSignOutIframes()}
    </Page>
  );
};

export default LoggedOut;
