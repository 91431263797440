import { post, get, patch } from "../helpers/apiHelper";

const UsersService = () => {
  const prefixApi = "main/users";

  const getUserAccountWithId = async (id) => {
    return await get(`/${prefixApi}/${id}/account`);
  };

  const getZendeskToken = async () => {
    return await get(`/${prefixApi}/zendesk/token`);
  };

  const postUser = async (vm) => {
    return await post(`/${prefixApi}`, vm);
  };

  const updateUser = async (data) => {
    return await patch(`/${prefixApi}`, data);
  };

  return {
    getUserAccountWithId,
    getZendeskToken,
    postUser,
    updateUser,
  };
};

export default UsersService;
