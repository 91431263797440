import React, { createContext, useContext, useState } from "react";

const LanguageContext = createContext();

const LanguageProvider = (props) => {
  const [languageData, setLanguageData] = useState("en");

  return (
    <LanguageContext.Provider value={{ languageData, setLanguageData }}>
      {props.children}
    </LanguageContext.Provider>
  );
};

const useLanguageData = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useAccountData must be used within a AccountDataProvider");
  }
  return context;
};

export { LanguageProvider, useLanguageData };
