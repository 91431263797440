import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Enums from "../types/Enums";

import { Page, Card, Button } from "@ediframework/ui.components";

const Error = ({ status }) => {
  const { t } = useTranslation(["Error", "Shared"]);

  const [statusCode, setStatusCode] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const goToEdiHome = () => {
    setBtnLoading(true);
    window.location.href = import.meta.env.VITE_MAIN_URL;
  };

  useEffect(() => {
    switch (status) {
      case Enums.HttpStatusCodes.InternalServerError:
        setStatusCode("500");
        setTitle(t("InternalServerError"));
        setDescription(t("InternalServerErrorDesc"));
        setIcon("laptop-code");
        break;
      case Enums.HttpStatusCodes.NotFound:
        setStatusCode("404");
        setTitle(t("NotFound"));
        setDescription(t("NotFoundDesc"));
        setIcon("file-exclamation");
        break;
      case Enums.HttpStatusCodes.Unauthorized:
        setStatusCode("401");
        setTitle(t("Unauthorized"));
        setDescription(t("UnauthorizedDesc"));
        setIcon("ban");
        break;
      default:
        break;
    }
  }, [status]);
  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <div className="text-center">
            <div className="text-center pb-3">
              <i className={`fad fa-${icon} fa-4x text-danger`}></i>
            </div>

            <Card.Title className="fw-bold text-center pb-3">
              {statusCode} - {title}
            </Card.Title>
            <Card.Text>{description}</Card.Text>

            <Button
              id="dashboard"
              type="primary"
              btnBlock="true"
              loading={btnLoading}
              onClick={goToEdiHome}
            >
              {t("Shared:GoToEDiHome")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default Error;
