import { post, get, patch } from "../helpers/apiHelper";

const AccountInvitationService = () => {
  const prefixApi = "main/accountinvitations";

  const getInvite = async (id) => {
    return await get(`/${prefixApi}/${id}`);
  };

  const patchInvite = async (data) => {
    return await patch(`/${prefixApi}`, data);
  };

  return {
    getInvite,
    patchInvite,
  };
};

export default AccountInvitationService;
