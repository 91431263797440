import React from "react";
import { withRouter, Route } from "react-router";
import authentication from "../../helpers/authentication";
import { Loading } from "@ediframework/ui.components";

authentication.initialize();

const AuthorizedRoute = ({component, ...rest}) => {
  let privateComponent = authentication.required(component, Loading(""));

  return <Route component={privateComponent} {...rest} />;
};

export default withRouter(AuthorizedRoute);
