import { post, get, patch } from "../helpers/apiHelper";

const UserInvitationsService = () => {
  const prefixApi = "main/userinvitations";

  const getUserInvitation = async (id) => {
    return await get(`/${prefixApi}/${id}`);
  };

  const patchUserInvitation = async (data) => {
    return await patch(`/${prefixApi}`, data);
  };

  return {
    getUserInvitation,
    patchUserInvitation
  };
};

export default UserInvitationsService;
