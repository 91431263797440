import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation(["Footer"]);

  return (
    <footer className="footer-component">
      <div className="d-flex justify-content-center p-4">
        <div className="text-center">
          © 1995-{new Date().getFullYear()} Computer Expert Group, LLC DBA
          CEGsoft, Inc. {t("AllRightReserved")}.
          <div id="policy-links" className="text-center">
            <a
              href="https://home.cegsoft.com/terms-and-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("PrivacyPolicy")}
            </a>
            |
            <a
              href="https://home.cegsoft.com/terms-and-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("TermsOfUse")}
            </a>
            |
            <a
              href="https://home.cegsoft.com/terms-and-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("DataProtectionPolicy")}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
