import React from "react";
import useBroadcastChannel from "../hooks/useBroadcastChannel";

const SignOut = () => {
  const { MessageTypes, postMessage } = useBroadcastChannel();

  postMessage(MessageTypes.SignOut);

  return <></>;
};

export default SignOut;
