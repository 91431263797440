import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "es",
    fallbackLng: "en",
    debug: false,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
      requestOptions: {
        cache: "no-store",
      },
    },
    // por si queremos que el cambio de language sea instantaneo - Oreo 8/31/2020
    preload: ["en", "es"],

    // para evitar un racing condition - Oreo 10/12/2020
    initImmediate: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
