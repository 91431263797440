import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import EdiLogo from "../../content/img/edi_logo.svg";
import { Container } from "@ediframework/ui.components";
import Footer from "./Footer";
import { useLanguageData } from "../../context/LanguageContext";

const Layout = ({ children, ediFooter }) => {
  const { i18n } = useTranslation();
  const { languageData, setLanguageData } = useLanguageData();

  const changeLanguage = () => {
    setLanguageData(languageData === "es" ? "en" : "es");
  };

  useEffect(() => {
    if (i18n.changeLanguage) i18n.changeLanguage(languageData);
  }, [languageData, i18n]);

  return (
    <Container fluid dflex flexcolumn className="flex-grow-1 h-100 bg-grayscale-300">
      <div className="d-flex justify-content-center my-4" id="branding-logo">
        <img src={EdiLogo} alt="Logo" />
      </div>
      <div id="main" className="d-flex flex-column flex-grow-1">
        {children}
      </div>
      {ediFooter && <Footer />}
    </Container>
  );
};

export default Layout;
