const useBroadcastChannel = () => {
  const MessageTypes = {
    SignOut: "sign-out",
  };

  const bc = new BroadcastChannel("edi-app-channel");

  const postMessage = (type, details = null) => {
    if (type) {
      let message = {
        type: type,
      };

      if (details) {
        message = { ...message, ...details };
      }

      bc.postMessage(message);
    }
  };

  const onMessage = (type, callback) => {
    bc.onmessage = (event) => {
      if (event?.data?.type === type) callback(event.data);
    };
  };

  return { MessageTypes, postMessage, onMessage };
};

export default useBroadcastChannel;
