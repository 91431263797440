import { post } from "../helpers/apiHelper";

const ConfirmationCodeService = () => {
  const prefixApi = "main/confirmationcodes";

  const postConfCode = async (data) => {
    return await post(`/${prefixApi}`, data);
  };

  return {
    postConfCode,
  };
};

export default ConfirmationCodeService;
