import axios from "axios";
import i18n from "i18next";
import authentication from "./authentication";
import { Notification } from "@ediframework/ui.components";
import Enums from "../types/Enums";

const apiHelper = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
});

const getErrorsList = (response) => {
  let errors = [];

  if (response.data?.Error) {
    errors.push(response.data.Error.Message);
  }

  if (response.data?.errors) {
    for (const value of Object.values(response.data.errors)) {
      errors.push.apply(errors, value);
    }
  }

  return errors;
};

apiHelper.interceptors.request.use(
  async (request) => {
    if (authentication.isLoggedIn()) {
      if (authentication.isTokenExpired()) {
        await authentication.acquireToken();
      }

      request.headers[
        "Authorization"
      ] = `Bearer ${authentication.getAccessToken()}`;
    }

    request.headers["Content-Type"] = "application/json";
    request.headers["User-Language"] = i18n.language;

    const accountId = window.sessionStorage.getItem("AccountId");

    if (accountId) request.headers["AccountId"] = accountId;

    return request;
  },
  (error) => {
    return error;
  }
);

apiHelper.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      let errors = getErrorsList(error.response);
      if (errors.length > 0) {
        Notification.errors(i18n.t("Shared:PleaseReviewErrors"), errors);
      } else if (error.response.status === Enums.HttpStatusCodes.Unauthorized) {
        Notification.error(i18n.t("Shared:Unauthorized"));
      } else if (error.response.status === Enums.HttpStatusCodes.NotFound) {
        Notification.error(i18n.t("Shared:NotFound"));
      } else {
        Notification.error(i18n.t("Shared:SystemError"));
      }
    } else {
      Notification.error(i18n.t("Shared:SystemError"));
    }

    return Promise.reject(error.response);
  }
);

export const get = (url, params = {}) => {
  return apiHelper.get(url, { params });
};

export const del = (url, params = {}) => {
  return apiHelper.delete(url, { params });
};

export const post = (url, data) => {
  return apiHelper.post(url, data);
};

export const put = (url, data) => {
  return apiHelper.put(url, data);
};

export const patch = (url, data) => {
  return apiHelper.patch(url, data);
};

export default apiHelper;
