import { useEffect } from "react";
import authentication from "../helpers/authentication";

import { useAccountData } from "../context/AccountDataContext";
import { useTranslation } from "react-i18next";

import UsersService from "../services/UsersService";

import {
  Card,
  Loading,
  Page,
  useQueryParams,
} from "@ediframework/ui.components";
import Constants from "../types/Constants";

const ZendeskLogin = () => {
  const { t } = useTranslation("ZendeskLogin");
  const { accountData } = useAccountData();
  const { getZendeskToken } = UsersService();
  const queryParams = useQueryParams();

  useEffect(() => {
    if (
      authentication.isLoggedIn() &&
      accountData.Id &&
      accountData.Id !== Constants.GuidEmpty
    ) {
      getZendeskToken().then((response) => {
        if (response && response.data) {
          let url = `${import.meta.env.VITE_ZENDESK_MAINURL}/access/jwt?jwt=${
            response.data.Token
          }`;

          const returnTo = queryParams.get("return_to");

          if (returnTo) {
            url += `&return_to=${returnTo}`;
          } else {
            url += `&return_to=${import.meta.env.VITE_ZENDESK_BRANDURL}`;
          }
          window.location.href = url;
        }
      });
    }
  }, [accountData.Id]);

  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <Loading relative={true} />
          <Card.Title className="fw-bold text-center pb-3">
            {t("ProcessingLogin")}
          </Card.Title>
          <Card.Text className="text-center">
            {t("PleaseWaitRedirect")}
          </Card.Text>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ZendeskLogin;
