import React, { Suspense, useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import authentication from "./helpers/authentication";

import useBroadcastChannel from "./hooks/useBroadcastChannel";

import { LanguageProvider } from "./context/LanguageContext";
import { AccountDataProvider } from "./context/AccountDataContext";

import { Loading, Notification } from "@ediframework/ui.components";
import Layout from "./components/app/Layout";
import AuthorizedRoute from "./components/app/AuthorizedRoute";

import Home from "./pages/Home";
import Error from "./pages/Error";
import CreateAccount from "./pages/CreateAccount";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordConfirmation from "./pages/ResetPasswordConfirmation";
import LoggedOut from "./pages/LoggedOut";
import InvalidInvitation from "./pages/InvalidInvitation";
import AccountDisabled from "./pages/AccountDisabled";
import ExpiredInvitation from "./pages/ExpiredInvitation";
import SignOut from "./pages/SignOut";
import ZendeskLogin from "./pages/ZendeskLogin";
import ZendeskLogout from "./pages/ZendeskLogout";

import Enums from "./types/Enums";

const App = () => {
  const { MessageTypes, onMessage } = useBroadcastChannel();

  const hideConsoleLog = () => {
    if (import.meta.env.VITE_ENVIRONMENT !== "LOCAL") {
      console.log = () => {};
    }
  };

  useEffect(() => {
    onMessage(MessageTypes.SignOut, (event) => {
      authentication.clearCache();
    });

    hideConsoleLog();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <LanguageProvider>
        <AccountDataProvider>
          <Layout ediFooter={true}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/CreateAccount/:id?" component={CreateAccount} />
              <Route
                exact
                path="/InvalidInvitation"
                component={InvalidInvitation}
              />
              <Route
                exact
                path="/ExpiredInvitation"
                component={ExpiredInvitation}
              />
              <Route
                exact
                path="/AccountDisabled"
                component={AccountDisabled}
              />
              <Route exact path="/ForgotPassword" component={ForgotPassword} />
              <Route exact path="/ResetPassword" component={ResetPassword} />
              <Route
                exact
                path="/ResetPasswordConfirmation"
                component={ResetPasswordConfirmation}
              />
              <Route exact path="/LoggedOut" component={LoggedOut} />
              <Route exact path="/SignOut" component={SignOut} />

              <Route path="/zdlogout" component={ZendeskLogout} />
              <AuthorizedRoute path="/zdlogin" component={ZendeskLogin} />
              {/* HTTP VERBS ROUTES */}
              <Route path="/401">
                <Error status={Enums.HttpStatusCodes.Unauthorized} />
              </Route>
              <Route path="/404">
                <Error status={Enums.HttpStatusCodes.NotFound} />
              </Route>
              <Route path="/500">
                <Error status={Enums.HttpStatusCodes.InternalServerError} />
              </Route>
              <Redirect to="/404" />
            </Switch>
            <Notification />
          </Layout>
        </AccountDataProvider>
      </LanguageProvider>
    </Suspense>
  );
};

export default App;
