import { post, get } from "../helpers/apiHelper";

const AccountService = () => {
  const prefixApi = "main/accounts";

  const getAccount = async (id) => {
    return await get(`/${prefixApi}/${id}`);
  };

  const postAccount = async (data) => {
    return await post(`/${prefixApi}`, data);
  };

  return {
    getAccount,
    postAccount,
  };
};

export default AccountService;
