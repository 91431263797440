import { useEffect } from "react";
import authentication from "../helpers/authentication";

import { useTranslation } from "react-i18next";

import { Card, Loading, Page } from "@ediframework/ui.components";

const ZendeskLogout = () => {
  const { t } = useTranslation("ZendeskLogout");

  useEffect(() => {
    if (authentication.isLoggedIn()) {
      authentication.signOut();
    } else {
      authentication.clearCacheAndRedirect();
    }
  }, []);

  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <Loading relative={true} />
          <Card.Title className="fw-bold text-center pb-3">
            {t("ProcessingLogout")}
          </Card.Title>
          <Card.Text className="text-center">
            {t("PleaseWaitRedirect")}
          </Card.Text>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ZendeskLogout;
