import React from "react";
import { Page, Card, Button } from "@ediframework/ui.components";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation("Shared");
  return (
    <Page centered showBg className="flex-fill align-items-center">
      <Card>
        <Card.Body>
          <Card.Title className="fw-bold text-center pb-4">
            {t("WelcomeToEdi")}
          </Card.Title>
          <Button
            id="buttonId"
            type="primary"
            btnBlock
            onClick={() =>
              (window.location.href = `${import.meta.env.VITE_MAIN_URL}`)
            }
          >
            {t("GoToLogin")}
          </Button>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default Home;
